import _ from 'lodash';

export const getUserName = user => `${user.firstName} ${user.lastName}`;

/**
 * @description Get field from user which might be at [organization, group, user] level.
 * @returns More specific field on hierarchy
 * @param {object} user
 * @param {String} field
 * @param {Boolean?} hierarchyName return name of the hierarchy where the field was found
 */
export const getHierarchicalField = (user, field, hierarchyName = false) => {
  // User level
  if (_.get(user, field)) {
    if (hierarchyName) {
      return {
        name: `${_.get(user, 'firstName')} ${_.get(user, 'lastName')}`,
        value: _.get(user, field),
      };
    }

    return _.get(user, field);
  }

  // Group level
  if (_.get(user, `group.${field}`)) {
    if (hierarchyName) {
      return {
        name: _.get(user, 'group.name'),
        value: _.get(user, `group.${field}`),
      };
    }

    return _.get(user, `group.${field}`);
  }

  // Organization level
  if (_.get(user, `group.organization.${field}`)) {
    if (hierarchyName) {
      return {
        name: _.get(user, 'group.organization.name'),
        value: _.get(user, `group.organization.${field}`),
      };
    }

    return _.get(user, `group.organization.${field}`);
  }

  if (hierarchyName) {
    return {
      name: null,
      value: null,
    };
  }

  return null;
};

export const hasAdminPermission = token => {
  const payload = JSON.parse(atob(token.split('.')[1]));
  const permissions = payload.permissions || [];

  return permissions.includes(
    'admin_dashboard:system:all:has_admin_permission',
  );
};
