import { useEffect, useState } from 'react';
import * as auth from 'auth';

const useToken = () => {
  const [token, setToken] = useState(false);

  useEffect(() => {
    (async () => {
      const authToken = await auth.getToken();
      setToken(authToken);
    })();
  }, []);

  return token;
};

export default useToken;
