import ky from 'ky';

import { getToken, logout } from 'auth';
import config from 'config';

export default ky.extend({
  prefixUrl: config.apiUrl,
  retry: 2,
  timeout: 30000,
  hooks: {
    beforeRequest: [
      async request => {
        const accessToken = await getToken();
        request.headers.set('Authorization', `Bearer ${accessToken}`);
        request.headers.set('x-application-name', 'db-admin-dashboard');
      },
    ],
    afterResponse: [
      async (_request, _options, response) => {
        if (response.status === 401) {
          await logout({
            result: 'error',
            msg: 'You are not authorized to access this page',
          });
        }

        return response;
      },
    ],
  },
});
