import useCasesConstants from './use-cases';

export { default as useCasesConstants } from './use-cases';
export { default as resourcesConstants } from './resources';

export default {
  databookLogoUrl:
    'https://res.cloudinary.com/haj2nljjq/image/upload/c_scale,h_58,q_auto/v1489271450/logo_color_kez7th.png',

  caseTypes: {
    useCase: 'use_case',
    caseStudy: 'case_study',
  },

  dialogModes: {
    edit: 'edit',
    create: 'create',
  },

  actions: {
    created: 'created',
    edited: 'edited',
    deleted: 'deleted',
    deleteDraft: 'deleteDraft',
  },

  googleStorage: {
    pipelineScoring: 'databook-pipeline-scoring',
  },

  lambdas: {
    status: {
      added: 'added',
      active: 'active',
      completed: 'completed',
      failed: 'failed',
      stopped: 'stopped',
    },
    intentDashboard: {
      group: 'intentDashboard',
      functions: {
        companies: 'companies',
        entities: 'entities',
        results: 'results',
        coverage: 'coverage',
      },
    },
    analytics: {
      group: 'analytics',
      functions: {
        transferData: 'transferData',
      },
    },
  },

  fields: {
    companyId: {
      field: 'databookId',
      description: 'databook id for a company',
    },
    organization: {
      field: 'organizationId',
      description: 'databook id of the organization',
    },
    companyName: { field: 'name', description: 'name of a company' },
    keywordName: { field: 'keyword', description: 'keyword value' },
    keywordWeight: { field: 'weight', description: 'numeric keyword weight' },
    keywordAliases: {
      field: 'aliases',
      description: 'comma-separated list of aliases',
    },
    valueDrivers: {
      names: Object.keys(useCasesConstants.valueDriversByName),
      ids: Object.keys(useCasesConstants.valueDriversById),
      description:
        'Value drivers as columns with "x" in cells to indicate selection',
    },
    name: { field: 'name' },
    listOfKeywords: {
      field: 'keywords',
      description: 'comma-separated list of keywords',
    },
    classifiers: {
      field: 'classifiers',
      description: 'comma-separated list of classifiers',
    },
    customerTags: {
      field: 'customTags',
      description:
        'comma-separated list of custom tags for Use Cases (ignored for Case Studies)',
    },
    listOfIndustries: (names, ids) => ({
      names,
      ids,
      description:
        'Industries as columns with "x" in cells to indicate selection',
    }),
  },
};
