import api from './base';

const me = {
  userData: async () => {
    const me = await api
      .get('users/me', {
        retry: {
          limit: 1,
        },
      })
      .json();

    return { me };
  },
  lists: options => api.get('users/me/lists', { searchParams: options }).json(),
};

export default me;
