import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { getToken, login } from 'auth';
import { hasAdminPermission } from 'shared/utils/user';
import * as User from 'models/user';
import { useMutation } from 'react-query';
import meApi from 'api/me';
import Spinner from 'components/progress/spinner';
import NoAccess from '../components/no-access';

const useStyles = makeStyles({
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
});

const PrivateRoute = ({ setUser, user, ...rest }) => {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(true);
  const [hasPermission, setHasPermission] = useState(false);

  const { mutate: fetchUser } = useMutation(() => meApi.userData(), {
    onSuccess: user => {
      setUser(user);
    },
  });

  useEffect(() => {
    (async () => {
      const token = await getToken();

      if (token && !!user) {
        setLoading(false);
        setHasPermission(hasAdminPermission(token));
      } else if (token) {
        setLoading(false);
        setHasPermission(hasAdminPermission(token));
        fetchUser();
      } else {
        // otherwise, the getToken should already trigger logging-in
        // but if that didn't happen for some reason, we can trigger it manually
        await login();
      }
    })();
  }, [user]);

  if (!isLoading && !hasPermission) {
    return <NoAccess />;
  }

  return !isLoading && user ? (
    <Route {...rest} />
  ) : (
    <div className={classes.spinner}>
      <Spinner />
    </div>
  );
};

PrivateRoute.propTypes = {
  user: PropTypes.object,
  setUser: PropTypes.func.isRequired,
};

const mapState = state => ({
  user: User.getUser(state),
});

const mapDispatch = dispatch => ({
  setUser: User.setUser(dispatch),
});

export default connect(
  mapState,
  mapDispatch,
)(PrivateRoute);
