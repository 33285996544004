/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import HomePage from 'containers/home-page';
import ControlPanel from 'containers/control-panel';
import UseCases from 'containers/use-cases';
import IntentDashboard from 'containers/intent-dashboard';
import NotFoundPage from 'containers/not-found-page';
import Interview from 'containers/interview';
import Healthchecks from 'containers/healthchecks';
import Navigation from 'containers/navigation';
import Organizations from 'containers/organizations';
import Courses from 'containers/courses';
import BuyingGroups from 'containers/buying-groups';
import SSO from 'containers/sso';
import Posts from 'containers/posts';
import Reports from 'containers/reports';
import CallbackPage from 'auth/callback-page';
import PrivateRoute from 'auth/private-route';
import * as User from 'models/user';
import NoAccess from 'components/no-access';

const hideLoader = () => {
  const loader = window.document.querySelector('#databook-splash-screen');

  if (loader) {
    loader.classList.replace(
      'databook-intro-loader',
      'databook-intro-loader-hidden',
    );
  }
};

const App = ({ user }) => {
  if (user) {
    hideLoader();
  }

  if (user && user.blocked) {
    return <NoAccess />;
  }

  return (
    <Navigation>
      <Switch>
        <Route exact path='/login' component={CallbackPage} />
        <PrivateRoute exact path='/' component={HomePage} />
        <PrivateRoute path='/organizations' component={Organizations} />
        <PrivateRoute path='/configuration/cases' component={UseCases} />
        <PrivateRoute path='/configuration/courses' component={Courses} />
        <PrivateRoute
          path='/configuration/buying-groups'
          component={BuyingGroups}
        />
        <PrivateRoute path='/configuration/sso' component={SSO} />
        <PrivateRoute
          path='/configuration/intent-dashboard'
          component={IntentDashboard}
        />
        <PrivateRoute path='/configuration/queue' component={Posts} />
        <PrivateRoute path='/interview' component={Interview} />
        <PrivateRoute path='/healthchecks' component={Healthchecks} />
        <PrivateRoute path='/control-panel' component={ControlPanel} />
        <PrivateRoute path='/reports' component={Reports} />
        <Route component={NotFoundPage} />
      </Switch>
    </Navigation>
  );
};

App.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = state => ({
  user: User.getUser(state),
});

export default connect(mapStateToProps)(App);
