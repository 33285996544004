import React from 'react';
import ConfigIcon from 'mdi-react/TuneIcon';
import InterviewIcon from 'mdi-react/CodeBracesIcon';
import HealthchecksIcon from 'mdi-react/AmbulanceIcon';
import ControllerIcon from 'mdi-react/ControllerClassicIcon';
import OrganizationIcon from 'mdi-react/DomainIcon';
import ReportIcon from 'mdi-react/ChartBarIcon';

const routes = [
  {
    label: 'Interview',
    icon: <InterviewIcon />,
    route: 'interview',
    highlight: true,
  },
  {
    label: 'Organizations',
    icon: <OrganizationIcon />,
    route: 'organizations',
    highlight: true,
  },
  {
    label: 'Configuration',
    icon: <ConfigIcon />,
    route: 'configuration',
    submenu: [
      {
        label: 'Cases',
        route: 'cases',
        submenu: [
          {
            label: 'Resource Library',
            route: 'resource-library',
            highlight: true,
          },
          {
            label: 'Case Editor',
            route: 'editor',
            highlight: true,
          },
        ],
      },
      {
        label: 'Intent Dashboard',
        route: 'intent-dashboard',
        highlight: true,
      },
      {
        label: 'Courses',
        route: 'courses',
        highlight: true,
      },
      {
        label: 'Buying Groups',
        route: 'buying-groups',
        highlight: true,
      },
      {
        label: 'SSO',
        route: 'sso',
        highlight: true,
      },
      {
        label: 'Posts',
        route: 'queue',
        highlight: true,
      },
    ],
  },
  {
    label: 'Reports',
    icon: <ReportIcon />,
    route: 'reports',
    highlight: true,
  },
  {
    label: 'Healthchecks',
    icon: <HealthchecksIcon />,
    route: 'healthchecks',
    highlight: true,
  },
  {
    label: 'Control Panel',
    icon: <ControllerIcon />,
    route: 'control-panel',
    highlight: true,
  },
];

const config = [];

const buildConfig = (items, collection, path = '') => {
  items.forEach(item => {
    const { submenu, route } = item;
    const fullPath = `${path}/${route}`;

    if (submenu) {
      const subItems = [];

      buildConfig(submenu, subItems, fullPath);
      collection.push({ ...item, fullPath, submenu: subItems });
    } else {
      collection.push({ ...item, fullPath });
    }
  });
};

buildConfig(routes, config);

export default config;
